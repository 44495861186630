import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { verifyCode } from "../api/code";
export const varifyCodeData = createAsyncThunk(
  "varify/varifyCodeData",
  async (_, { rejectWithValue }) => {
    try {
      const phone = sessionStorage.getItem("phone");
      const code = sessionStorage.getItem("code");
      const { data } = await verifyCode({ phone, code }); // Call the API function
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message); // Handle errors properly
    }
  }
);

const initialValues = {
  customerId: null,
  isLoading: false,
  isError: null,
};

const codeVerifySlice = createSlice({
  name: "varify",
  initialState: initialValues,
  extraReducers: (builder) => {
    builder
      .addCase(varifyCodeData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customerId = action.payload;
        localStorage.setItem("customer_id", action.payload?.data?.customer_id);
        // console.log(action.payload);
      })
      .addCase(varifyCodeData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(varifyCodeData.rejected, (state, action) => {
        state.isError = action.error;
        state.isLoading = false;
        // console.log(action.error);
      });
  },
  reducers: {},
});

export const codeVerifyReducer = codeVerifySlice.reducer;
