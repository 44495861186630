import React, { useState } from "react";
import { Suspense } from "react";
// import Spinner from "../components/spinner";

// Lazy load the child components
const SelfieLink = React.lazy(() => import("./sections/SelfieLink"));
const ServicesLink = React.lazy(() => import("./sections/ServicesLink"));
const ShopLink = React.lazy(() => import("./sections/ShopLink"));

export default function GetStart() {
  const [imageLoaded, setImageLoaded] = useState({
    getStartedImage: false,
    bgImage: false,
    selfieFrame: false,
    servicesFrame: false,
    shopFrame: false,
  });

  const handleImageLoad = (imageName) => {
    setImageLoaded((prev) => ({
      ...prev,
      [imageName]: true,
    }));
  };

  return (
    <>
      <div className="">
        <img
          src="./images/get-started.webp"
          alt="get-started-header"
          className="w-[100dvw] "
          onLoad={() => handleImageLoad("getStartedImage")}
          loading="lazy"
        />
        <img
          src="/images/getstarted-bg.webp"
          alt="get-started-pattern"
          className="absolute bottom-0 left-0 w-full -z-30 "
          onLoad={() => handleImageLoad("bgImage")}
          loading="lazy"
        />
      </div>

      {/* Main content */}
      <section className="relative h-[77.4dvh] flex flex-col w-full justify-center gap-[3dvh] mx-auto z-20 text-white">
        <Suspense fallback={<div>Loading Selfie Link...</div>}>
          <SelfieLink
            imageLoaded={imageLoaded}
            handleImageLoad={handleImageLoad}
          />
        </Suspense>
        <Suspense fallback={<div>Loading Services Link...</div>}>
          <ServicesLink
            imageLoaded={imageLoaded}
            handleImageLoad={handleImageLoad}
          />
        </Suspense>
        <Suspense fallback={<div>Loading Shop Link...</div>}>
          <ShopLink
            imageLoaded={imageLoaded}
            handleImageLoad={handleImageLoad}
          />
        </Suspense>
      </section>
    </>
  );
}
