import React, { useEffect } from "react";
import BackIcon from "../../icons/backIcon";
import { useNavigate } from "react-router-dom";
import VerificationHeader from "../../components/verificationHeader";

import { Bounce, toast } from "react-toastify";

export default function Payment() {
  const navigate = useNavigate();

  const success = () =>
    toast("Order created successfully", {
      style: {
        background: "#84cc16",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1.2rem",
        padding: "1rem",
      },
      position: "top-center",
      autoClose: 1750,
      hideProgressBar: false,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
      transition: Bounce,
    });

  const handlePaymentSubmit = () => {
    // Simulate dispatching an action to get order data
    // dispatch(getOrderDoneData());
    // Navigate to the order details page
    navigate("/doneOrder");
    success();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handlePaymentSubmit();
    }, 5000); // 5 seconds delay

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <>
      <VerificationHeader />
      <article
        className="relative flex flex-col justify-center h-[85vh]"
        id="verification"
      >
        {/* Back Button */}
        <div
          onClick={() => {
            sessionStorage.removeItem("phone");
            sessionStorage.removeItem("code");
            navigate("/phoneVerification");
          }}
          className="absolute top-[1dvh] p-[.5dvh] rounded-[1.3dvh] left-[0.6dvh] rtl:left-auto rtl:right-[0.6dvh] cursor-pointer bg-[#0000001A] hover:bg-[#00000021]"
        >
          <BackIcon className={"w-[3dvh] h-[3dvh] rtl:rotate-180"} />
        </div>

        <div
          className="mx-auto w-2/3 border backdrop-blur-[1.2dvh] rounded-[1dvh] overflow-hidden"
          style={{
            background:
              "linear-gradient(181.35deg, rgba(255, 255, 255, 0.5) 1.15%, rgba(255, 255, 255, 0) 98.91%)",
            border:
              "2px solid border-image-source: linear-gradient(147.91deg, #FFFFFF 9.57%, rgba(255, 255, 255, 0) 56.98%, rgba(255, 255, 255, 0.77) 97.79%)",
          }}
        >
          <img
            src="./images/payment.webp"
            alt="payment"
            className="w-[15dvh] h-[15dvh] mx-auto mt-[3dvh]"
            loading="lazy"
          />
          <h1 className="text-center text-[#66381a] pb-[0dvh] pt-[2dvh] text-[3dvh] font-bold uppercase">
            Payment
          </h1>
          <div className="flex flex-col justify-between pt-[0.6dvh] mb-[3dvh] gap-[2dvh] mx-[1.8dvh]">
            <p className="text-center text-[black] text-[2.1dvh] font-semibold capitalize">
              Please Scan Your Payment card
            </p>
            <p className="text-center text-[#4F4F4F] text-[1.6dvh] font-bold uppercase">
              or
            </p>
            <button
              onClick={handlePaymentSubmit}
              className="text-center rounded-[.8dvh] py-[0.5dvh] text-[white] bg-[#A4907C] text-[1.8dvh] capitalize font-semibold font-Raleway"
            >
              Pay at the cashier
            </button>
          </div>
        </div>
      </article>
    </>
  );
}
