import React from "react";
import { useNavigate } from "react-router-dom";

export default function DetailsOrder() {
  const navigate = useNavigate();
  const cartItems = JSON.parse(sessionStorage.getItem("cartItems"));
  const products = cartItems?.product;
  const products_qty = cartItems?.product_qty;

  const purchasesProducts = products.filter(
    (product) => product?.type === "Purchases" || product?.type === "purchases"
  );
  const services = products.filter(
    (product) => product?.type !== "Purchases" && product?.type !== "purchases"
  );

  const renderTable = (title, data) => (
    <div className="w-full max-w-[92dvw] bg-white shadow-custom rounded-lg overflow-hidden my-8">
      <h2 className="font-extrabold text-main text-center text-[2.5dvh] mb-4">
        {title}
      </h2>
      <table className="table-auto w-full">
        <thead className="bg-main">
          <tr className="text-left text-white font-semibold">
            <th className="px-[1dvh] py-[.6dvh] text-[1dvh]">Image</th>
            <th className="px-[1dvh] py-[.6dvh] text-[1dvh]">Name</th>
            <th className="px-[1dvh] py-[.6dvh] text-[1dvh]">Quantity</th>
            <th className="px-[1dvh] py-[.6dvh] text-[1dvh]">Price</th>
            {title == "Booked Services" && (
              <th className="px-[1dvh] py-[.6dvh] text-[1dvh]">Duration</th>
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((product, index) => (
            <tr
              key={product?.id}
              className="border-b hover:bg-orange-50 transition"
            >
              <td className="px-[1dvh] py-[.6dvh]">
                <img
                  src={product?.image}
                  alt="product"
                  className="w-[5dvh] h-[5dvh] object-cover rounded-[1dvh]"
                  loading="lazy"
                />
              </td>
              <td className="px-[1dvh] py-[.6dvh] text-gray-700 font-bold text-[1.5dvh]">
                {product?.descriptions[0]?.name || product?.keyword}
              </td>
              <td className="px-[1dvh] py-[.6dvh] text-gray-700 font-bold text-[1.5dvh]">
                {products_qty[index]}
              </td>
              <td className="px-[1dvh] py-[.6dvh] text-gray-700 font-bold text-[1.5dvh]">
                {products_qty[index] * product?.price} SAR
              </td>
              {title == "Booked Services" && (
                <td className="px-[1dvh] py-[.6dvh] text-gray-700 font-bold text-[1.5dvh]">
                  {product?.unit?.main_unit_id >= 60 &&
                    product?.unit?.main_unit_id / 60}{" "}
                  {product?.unit?.main_unit_id >= 60 ? "Hour" : "Minutes"}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="min-h-screen bg-verification flex flex-col items-center px-4 py-8">
      {/* Header */}
      <h1 className="font-extrabold text-white text-[3dvh] text-center mb-10">
        Order Details
      </h1>

      {/* Tables */}
      {purchasesProducts.length > 0 &&
        renderTable("Products Receipt", purchasesProducts)}
      {services.length > 0 && renderTable("Booked Services", services)}

      {/* Start New Operation Button */}
      <div className="mt-[3dvh]">
        <button
          onClick={() => {
            sessionStorage.removeItem("phone");
            sessionStorage.removeItem("cartItems");
            sessionStorage.removeItem("code");
            sessionStorage.removeItem("customer_id");
            navigate("/");
          }}
          className="bg-main mt-[1dvh] capitalize hover:bg-main/60 hover:scale-110 border-[.2dvh] border-white/70 w-[40dvh] text-white text-[2.4dvh] font-medium px-[1dvh] py-[0.5dvh] rounded-[1dvh] shadow-custom transition-all"
        >
          Start New Operation
        </button>
      </div>
    </div>
  );
}
