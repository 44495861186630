import axios from "axios";

const axiosClient = axios.create({
  baseURL: "https://testecommerce.vodoglobal.com/api", // Base API URL
  timeout: 5000, // Timeout limit
});

// Add request interceptors (e.g., Authorization tokens)
axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("authToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosClient;
