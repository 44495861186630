import React, { useState } from "react";
import PhoneIcon from "../../icons/phoneIcon";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { FaRegKeyboard } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { getCodeData } from "../../services/Code/sendCodeSlice";
import Spinner from "../../components/spinner";
import VerificationHeader from "../../components/verificationHeader.jsx";
import BackIcon from "../../icons/backIcon.jsx";
import { IoMdClose } from "react-icons/io";

export default function PhoneVerification() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isShowKeyboard, setIsShowKeyboard] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // const codeData = useSelector((state) => state?.sendCode?.code);
  // const codeMessage = codeData?.message;

  const validationForm = yup.object({
    country: yup.string().required(t("countryRequired")),
    customer_phone: yup
      .string()
      .required(t("numberRequired"))
      .test("valid-phone", "Number is not valid", function (value) {
        const { country } = this.parent;
        if (value && value.length === 7) {
          value = value + "00";
        }
        const phoneNumber = parsePhoneNumberFromString(
          value,
          country === "KSA" ? "SA" : "EG"
        );
        return phoneNumber ? phoneNumber.isValid() : false;
      }),
  });

  const formik = useFormik({
    initialValues: {
      country: "KSA",
      customer_phone: "",
    },
    validationSchema: validationForm,
    onSubmit: async (values, { resetForm }) => {
      if (formik.isValid) {
        const countryCode = values.country === "KSA" ? "+966" : "+20";
        const phone = countryCode + values.customer_phone;
        sessionStorage.setItem("phone", phone);

        setIsLoading(true);

        try {
          const result = await dispatch(getCodeData()).unwrap();

          setIsLoading(false);

          if (result) {
            navigate("/codeVerification");
          } else {
            console.log("Failed to generate code");
          }
        } catch (error) {
          console.error("Error generating code:", error);
          setIsLoading(false);
        }
        resetForm();
      } else {
        console.log("Invalid form");
      }
    },
  });

  const handleInputChange = (value) => {
    const currentPhone = formik.values.customer_phone;
    if (value === "delete") {
      formik.setFieldValue("customer_phone", currentPhone.slice(0, -1));
    } else {
      formik.setFieldValue("customer_phone", currentPhone + value);
    }
  };

  const handleKeyDown = (e) => {
    e.preventDefault(); // Prevent default input behavior to avoid duplication
    if (e.key >= "0" && e.key <= "9") {
      handleInputChange(e.key);
    } else if (e.key === "Backspace") {
      handleInputChange("delete");
    }
  };
  const handleBackClick = () => {
    const previousPath = location.state?.from?.pathname || location.pathname;

    if (previousPath === "/services" || previousPath === "/shop") {
      navigate(-1); // Navigate back
    } else {
      navigate("/"); // Navigate to the home page
    }
  };

  return (
    <section className="">
      {isLoading && <Spinner />} <VerificationHeader />
      <article
        className="relative h-[85vh]  container-box  flex flex-col justify-center"
        id="verification"
      >
        <div
          onClick={handleBackClick}
          className="absolute top-[1dvh] p-[.5dvh] rounded-[1.3dvh] left-[0.6dvh] rtl:left-auto rtl:right-[0.6dvh] cursor-pointer bg-[#0000001A] hover:bg-[#00000021]"
        >
          <BackIcon className={"w-[3dvh] h-[3dvh] rtl:rotate-180"} />
        </div>
        <div className="mx-auto w-full border border-white backdrop-blur-md bg-white/30 rounded-2xl overflow-hidden">
          <h1 className="text-center text-[#66381a] pb-[2dvh] pt-[.8dvh] text-[3.4dvh] font-semibold">
            Phone Verification
          </h1>
          <form className="mx-auto w-4/5" onSubmit={formik.handleSubmit}>
            <div className="flex items-center border bg-white border-[#E5E5E5] gap-[.6dvh] rounded-[0.8dvh]  ps-[1dvh]">
              <PhoneIcon className="w-[2dvh] h-[2dvh] " />
              <div>
                <select
                  name="country"
                  className="block w-[4dvh] text-[1.2dvh] focus:outline-none bg-transparent"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.country}
                >
                  <option value="KSA">KSA</option>
                  <option value="EGY">EGY</option>
                </select>
              </div>
              <div className="w-full">
                <input
                  type="tel"
                  name="customer_phone"
                  value={formik.values.customer_phone}
                  placeholder="Enter your phone number"
                  className="w-full text-[1.2dvh] border-none focus:outline-none rounded-md py-[1dvh] bg-transparent"
                  onKeyDown={handleKeyDown}
                  onClick={() => setIsShowKeyboard(true)}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
              </div>
            </div>
            {formik.errors.customer_phone && formik.touched.customer_phone ? (
              <span className="text-red-700 text-[1dvh]">
                * {formik.errors.customer_phone}{" "}
              </span>
            ) : null}
            <button
              type="submit"
              className="w-full bg-[#e7ded5] text-main text-[1.7dvh] border border-main py-[0.8dvh] my-[2dvh] font-bold rounded-[0.6dvh]"
            >
              Verify
            </button>
            <div className="text-center">
              <p className="text-black font-bold text-[1.2dvh] ">
                Scan QR Code and install App
              </p>
            </div>
            <img
              src="/images/qr.svg"
              alt="qr"
              className="w-[9dvh] mx-auto"
              loading="lazy"
            />
          </form>

          <div
            onClick={() => setIsShowKeyboard(true)}
            className="cursor-pointer mx-auto w-fit"
          >
            <FaRegKeyboard className="text-black w-[2.4dvh] h-[2.4dvh]" />
          </div>
          {isShowKeyboard && (
            <div className="bg-white w-full pb-[1dvh]">
              <div
                onClick={() => setIsShowKeyboard(false)}
                className="w-[5dvh] cursor-pointer ms-[1dvh] font-bold text-[2.2dvh]"
                loading="lazy"
              >
                <IoMdClose />
              </div>
              <div className="grid grid-cols-3 gap-[0.5dvh] my-[0.3dvh] w-full mx-auto">
                {["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].map(
                  (num) => (
                    <button
                      key={num}
                      onClick={() => handleInputChange(num)}
                      className="p-[0.5dvh] bg-gray-300 text-[2dvh] rounded-lg"
                    >
                      {num}
                    </button>
                  )
                )}
                <button
                  onClick={() => handleInputChange("delete")}
                  className="col-span-2 p-[0.5dvh] bg-main text-white text-[2dvh] rounded-lg"
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </div>
      </article>
    </section>
  );
}
