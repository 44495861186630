import { useNavigate } from "react-router-dom";
import ProductsAddRemove from "../../../components/productAddRemove";

function SelectedProducts({
  cartItems,
  //   item,
  setCartItems,
  handleDecreaseQty,
  handleIncreaseQty,
  setIsShow,
}) {
  const navigate = useNavigate();
  //   console.log("cartIn cart", cartItems);
  const filteredCartItems = {};

  const isService = cartItems.is_service;
  const handleRemove = (index) => {
    const updatedItems = { ...cartItems };

    updatedItems.product.splice(index, 1);
    updatedItems.product_id.splice(index, 1);
    updatedItems.product_price.splice(index, 1);
    updatedItems.product_qty.splice(index, 1);
    updatedItems.professionals.splice(index, 1);
    updatedItems.professionals_id.splice(index, 1);
    updatedItems.is_service.splice(index, 1);

    // Update session storage and state
    sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
    setCartItems(updatedItems);
  };

  // Iterate over the keys of the original object
  Object.keys(cartItems).forEach((key) => {
    // For each key, filter its values based on the indexes of `is_service` that are `false`
    filteredCartItems[key] = cartItems[key].filter(
      (_, index) => isService[index] === false
    );
  });

  // console.log("filteredCartItems", filteredCartItems);
  return (
    <div>
      <div className=" flex flex-col justify-normal">
        <p className="text-[1.2dvh]  !py-[1dvh] w-full  text-center font-bold bg-main text-white">
          Selected Products
        </p>
        <div className="flex flex-col gap-[0.5dvh] px-[3dvh] !overflow-auto !max-h-[10dvh] py-[1dvh]">
          {filteredCartItems?.product?.length > 0 ? (
            filteredCartItems.product.map((data, i) => (
              <div className="flex justify-between " key={i}>
                <div className="flex justify-between gap-[2dvh]">
                  <div className="  w-[4dvh] h-[4dvh]  ">
                    <img
                      src={data?.image}
                      alt={data?.name || "No Image"}
                      className="w-full h-full object-center object-cover rounded-[1dvh] "
                      loading="lazy"
                    />
                  </div>
                  <div className="flex flex-col justify-center gap-[0.1dvh]">
                    <p className="text-black text-[1.6dvh] my-auto font-bold">
                      {data?.descriptions[0]?.name?.length > 27
                        ? `${data.descriptions[0].name.substring(0, 27)}...`
                        : data?.descriptions[0]?.name ||
                          (data?.keyword?.length > 27
                            ? `${data.keyword.substring(0, 27)}...`
                            : data?.keyword) ||
                          "No Name"}
                    </p>
                    <p className="text-black text-[1dvh] font-bold my-auto">
                      SAR {data?.customer_price || "00.00"}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between gap-[4dvh]">
                  <div className="   my-auto ">
                    {" "}
                    <ProductsAddRemove
                      item={data}
                      handleIncreaseQty={handleIncreaseQty}
                      handleDecreaseQty={handleDecreaseQty}
                      cartItems={cartItems}
                    />
                  </div>
                  <div
                    onClick={() => handleRemove(i)}
                    className="flex justify-center items-center my-auto  cursor-pointer rounded-[12px] h-[2dvh] w-[2dvh]  "
                  >
                    <img
                      src="./images/fi_trash.svg"
                      alt="Delete"
                      className="h-[8dvw] w-[8dvw] mx-auto object-contain"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex flex-col justify-between max-h-[11dvh]">
              <img
                src="./images/empty-cart.svg"
                alt="empty cart"
                className="w-[6dvh] h-[6dvh] m-auto"
                loading="lazy"
              />
              <p className="text-center text-[1.2dvh]">Your order is empty</p>
            </div>
          )}
        </div>

        <div className="w-full h-[8dvh] my-auto flex flex-col justify-center bg-[#FAF9F5]">
          <div className="flex justify-center gap-[.8dvh] px-[2dvh] ">
            <button
              onClick={() => {
                //   sessionStorage.removeItem("cartItems");
                navigate("/");
                sessionStorage.removeItem("cartItems");
              }}
              className="w-1/2 text-center text-[#A91115] font-bold bg-[#EFE8E2] 
                 rounded-[.6dvh] !py-[1dvh]  text-[1.3dvh] "
            >
              Cancel
            </button>
            {filteredCartItems?.product?.length > 0 && (
              <button
                onClick={() => {
                  setIsShow(true);
                }}
                disabled={cartItems.product.length === 0}
                className="w-1/2 text-center text-[#FFFFFF] font-bold bg-[#D6C4B0] 
                 rounded-[.6dvh] !py-[1dvh]  text-[1.3dvh] "
              >
                Confirm
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectedProducts;
