import axiosClient from "./axiosClient";
import { apiEndpoints, secureBusinessKey } from "./apiEndpoints";

// Fetch employees for a specific service ID
export const fetchEmployees = ({ serviceId }) =>
  axiosClient.get(apiEndpoints.employees.url(serviceId), {
    headers: {
      "secure-business-key": secureBusinessKey,
      uuid: apiEndpoints.employees.uuid,
    },
  });

// Search employees by service ID and branch ID
export const searchEmployees = (serviceId, branchId) =>
  axiosClient.get(apiEndpoints.employeesSearch.url(serviceId, branchId), {
    headers: {
      "secure-business-key": secureBusinessKey,
      uuid: apiEndpoints.employeesSearch.uuid,
    },
  });

// Fetch specialists' availability by employee IDs
export const fetchSpecialists = (employeeIds) =>
  axiosClient.post(
    "https://testcalendar.vodoglobal.com/api/available-specialists",
    { employee_id: employeeIds },
    {
      headers: {
        "secure-business-key": secureBusinessKey,
        "Content-Type": "application/json",
      },
    }
  );
