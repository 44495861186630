import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCategories } from "../api/categories"; // Import the function

const allCategories = {
  id: 1,

  parent: "",
  sort: 0,
  status: 1,
  title: "All",
  top: 1,
};

export const fetchSlides = createAsyncThunk(
  "categories/fetchSlides",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchCategories();
      // console.log("res", response.data.data.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch slides"
      );
    }
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    slides: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSlides.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSlides.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Extracts the nested array from response.data.data.data
        state.slides = Array.isArray(action.payload.data.data)
          ? [...action.payload.data.data, allCategories] // Append allCategories last
          : [allCategories]; // If no slides, only include allCategories
      })
      .addCase(fetchSlides.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const categoriesReducer = categoriesSlice.reducer;
