import { useEffect } from "react";

const HandleEmptyCart = ({ cartItems, setIsShow }) => {
  useEffect(() => {
    // Check if cartItems.product exists and its length is 0
    if (cartItems?.product?.length === 0) {
      setIsShow(false);
    }
  }, [cartItems, setIsShow]); // Dependencies to watch

  return null; // This component does not render anything
};

export default HandleEmptyCart;
