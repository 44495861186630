import { useState, useRef, useEffect } from "react";

const SearchButton = ({ searchQuery, setSearchQuery }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const tooltipRef = useRef(null); // Reference to the tooltip container

  const handleSearchClick = () => {
    setIsTooltipVisible((prev) => !prev); // Toggle tooltip visibility
  };
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  // console.log("searchQuery", searchQuery);
  // Handle click outside the tooltip
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setIsTooltipVisible(false); // Close the tooltip
        setSearchQuery("");
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div
        onClick={handleSearchClick}
        className="relative flex justify-center items-center py-[.7dvh] px-[1.1dvh] border-black/40 border-[0.16dvh] rounded-[.8dvh]"
      >
        <img
          src="./images/fi_search.svg"
          alt="SearchIcon"
          className="w-[1.6dvh] h-[1.6dvh] cursor-pointer"
          loading="lazy"
        />
      </div>

      {isTooltipVisible && (
        <div
          ref={tooltipRef}
          className="absolute top-[5dvh] -left-[11.2dvh] bg-[#E5E5E5]  z-50  p-[1.2dvh] rounded-[1dvh] shadow-custom"
        >
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleInputChange}
            className="p-[1dvh] text-[1.4dvh] w-[24dvh] h-[3dvh] bg-white border-[.2dvh] rounded-[0.2dvh] focus:!border-blue-500 focus:ring focus:ring-blue-500 focus-visible:ring-blue-500 active:!border-blue-500 focus-visible:!border-blue-500"
          />
          <img
            src="./images/fi_search.svg"
            alt="SearchIcon"
            className="w-[1.6dvh] h-[1.6dvh] absolute top-0 bottom-0 my-auto right-[2dvh] cursor-pointer"
            loading="lazy"
          />
          <div className="absolute bottom-[100%] left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-[1dvh] border-r-[1dvh] border-b-[1dvh] border-transparent border-b-[#E5E5E5]" />
        </div>
      )}
    </div>
  );
};

export default SearchButton;
