function ProductsLoader() {
  return (
    <div className="flex w-3/4 justify-center items-center h-full z-10">
      <div className="loader">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    </div>
  );
}

export default ProductsLoader;
