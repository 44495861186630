// src/context/EmployeesContext.js
import React, { createContext, useState } from "react";

export const EmployeesContext = createContext();

export const EmployeesProvider = ({ children }) => {
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [serviceSelectedId, setServiceSelectedId] = useState(null);
  const [proffessionalsModel, setProffessionalsModel] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const handleGetEmployees = (serviceId) => {
    setServiceSelectedId(serviceId);
    setProffessionalsModel(true);
    setSelectedEmployeeId(null);
  };

  return (
    <EmployeesContext.Provider
      value={{
        selectedEmployeeId,
        setSelectedEmployeeId,
        serviceSelectedId,
        setServiceSelectedId,
        proffessionalsModel,
        setProffessionalsModel,
        isShow,
        setIsShow,
        handleGetEmployees,
      }}
    >
      {children}
    </EmployeesContext.Provider>
  );
};
