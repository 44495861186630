import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa6";

export default function ProductsAddRemove({
  cartItems,
  handleDecreaseQty,
  handleIncreaseQty,
  item,
}) {
  const itemIndex = cartItems?.product_id?.findIndex((x) => x === item?.id);
  // console.log("itemIndex", item?.id);
  return (
    <>
      {
        <div className="flex justify-center items-center gap-[3dvh] ">
          <div
            onClick={() => handleDecreaseQty(itemIndex)}
            className={` ${
              cartItems?.product_qty[itemIndex] == 0 ||
              (itemIndex == -1 &&
                "cursor-not-allowed opacity-35 bg-white pointer-events-none")
            } flex justify-center items-center bg-secondary cursor-pointer border
                         border-main rounded-full h-[2dvh] w-[2dvh] `}
          >
            <FaMinus
              className={`w-[1.2dvh] h-[1.2dvh] ${
                cartItems?.product_qty[itemIndex] == 0 ||
                (itemIndex == -1 ? "text-red-700" : "text-[#3F1A01]")
              } `}
            />
          </div>
          <p
            className="text-[16px] sm:text-[20px] md:text-[24px] lg:text-[28px] xl:text-[32px] 
                       2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 
                       7xl:text-[80px] font-semibold"
          >
            {cartItems?.product_qty[itemIndex] ?? 0}
          </p>
          <div
            onClick={() => handleIncreaseQty(item)}
            className="flex justify-center items-center bg-secondary cursor-pointer border border-main rounded-full h-[2dvh] w-[2dvh]"
          >
            <FaPlus className="w-[1.2dvh] h-[1.2dvh] text-[#3F1A01]" />
          </div>
        </div>
      }
    </>
  );
}
