//
//
//
import { useEffect } from "react";
import Logo from "../../icons/logo";
// import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export default function Welcome() {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);
  return (
    <section className="relative h-full" id="welcome">
      <img
        src="/images/welcome-pattern.webp"
        alt="welcome-pattern"
        className="absolute top-0 left-0 w-full z-30"
        loading="lazy"
      />

      <div className=" relative flex flex-col justify-top top-[10dvh] w-full  mx-auto  text-white">
        <div className="mx-auto w-fit z-50">
          <Logo fill={"black"} className="h-[24dvh] w-[34dvw]" />
        </div>
        <h1 className="text-[5dvh] text-center mx-auto z-50">
          Welcome To
          <span className="text-black ms-[.8dvh] font-black">Layers</span>
        </h1>

        <p
          className="w-full px-[.2dvh] text-center mx-auto  font-light  mt-[5dvh] leading-[3.5dvh]
        text-[3dvh] z-50
       "
        >
          Unwind and embrace your unique beauty with our personalized treatments
          and exceptional service.
        </p>
        <Link to={"/home"} className="z-50 ">
          <button className=" w-3/5  mx-auto font-semibold my-[5dvh] bg-white text-main block py-[.7dvh] rounded-[.8dvh] text-[2dvh] capitalize">
            get started
          </button>
        </Link>
        <div className=" ">
          <div className="relative -bottom-[1.5dvh] right-0 left-0 z-50  mx-auto">
            <img
              src="/images/qr.svg"
              alt="qr"
              className="w-[9dvh]  mx-auto"
              loading="lazy"
            />
            <p className=" text-center text-[2dvh] uppercase font-bold text-black">
              Download Application
            </p>
          </div>
          <img
            src="/images/welcome.webp"
            alt="welcome"
            className="fixed bottom-0 w-[100dvw]  mx-auto z-20"
            loading="lazy"
          />
        </div>
      </div>
    </section>
  );
}
