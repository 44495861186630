export default function Header() {
  return (
    <header className="   z-10">
      <nav className="">
        {/* <div className="">
          <p className="text-[3dvw]    font-semibold ">Enjoy Exclusive</p>
          <p className="text-[3dvw]  font-extrabold">Rewards</p>
        </div>
        <div className="w-[30dvw]   !border-0 !shadow-none">
          <img
            src="./images/header-image.svg "
            alt=""
            className="w-full h-full !border-none !shadow-none"
          />
        </div> */}
        <img
          src="./images/header.webp"
          alt="header"
          className="w-full  !border-none !shadow-none "
          loading="lazy"
        />
      </nav>
    </header>
  );
}
