import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.removeItem("phone");
    sessionStorage.removeItem("cartItems");
    sessionStorage.removeItem("code");
    sessionStorage.removeItem("customer_id");
    navigate("/login");
  }, [navigate]);

  return null; // No UI needed
};

export default Logout;
