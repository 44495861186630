import React, { lazy } from "react";
import Logo from "../icons/logo";

const verificationHeader = () => {
  return (
    <div className=" top-0 right-0 left-0 bg-white border-b-[.35dvh] border-[#D6C4B0] shadow-md shadow-slate-300 z-50  h-[15vh]">
      <Logo
        fill={"black"}
        className="h-[15dvh] w-[24dvw]  mx-auto"
        loading={lazy}
      />
    </div>
  );
};

export default verificationHeader;
