import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { secureBusinessKey, apiEndpoints } from "../.info";

export const getOrderDoneData = createAsyncThunk(
  "order/getOrderDoneData",
  async () => {
    const cartItems = sessionStorage.getItem("cartItems");

    const servicesIdArray = JSON.parse(cartItems).product_id;
    const servicesQtyArray = JSON.parse(cartItems).product_qty;
    const servicesPriceArray = JSON.parse(cartItems).product_price;
    const employeesIdArray = JSON.parse(cartItems).professionals_id;

    const todayDate = String(new Date().toISOString().split("T")[0]);

    const customerId = localStorage.getItem("customer_id");
    const branchId = sessionStorage.getItem("brunch_id");
    const orderID = sessionStorage.getItem("order_id");
    // Calculate the current time and one hour later time
    const startTime = new Date();
    const endTime = new Date(startTime.getTime() + 60 * 60 * 1000); // Adds one hour

    // Format times to "HH:MM" format
    const formatTime = (date) =>
      `${date.getHours().toString().padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;

    // Generate arrays for start and end times based on the number of cart items
    const startTimeArray = Array(servicesQtyArray.length).fill(
      formatTime(startTime)
    );
    const endTimeArray = Array(servicesQtyArray.length).fill(
      formatTime(endTime)
    );

    const options = {
      url: apiEndpoints.orderReservation.url,
      method: "POST",
      headers: {
        "Accept-Language": "en",
        "secure-business-key": secureBusinessKey,
      },
      data: {
        title: `new reservation on  ${todayDate}`,
        customer_id: customerId,
        start_date: todayDate,
        end_date: todayDate,
        start_time: startTimeArray,
        end_time: endTimeArray,
        service_id: servicesIdArray,
        service_price: servicesPriceArray,
        service_qty: servicesQtyArray,
        employee_id: employeesIdArray,
        color: "#000",
        background_color: "#uuuu",
        branch_id: branchId,
        ecomm_order_id: orderID,
      },
    };

    try {
      const { data } = await axios.request(options);
      // console.log("data", data);

      const parentReservationIds = data?.data?.id;
      // console.log("parentReservationIds", parentReservationIds);
      const reservationIds = data.data.items.map((item) => item.id);
      // console.log(reservationIds);
      const serviceIds = data.data.items.map((item) => item.service_id);
      // console.log("serviceIds", serviceIds);

      const handleReservationOptions = {
        url: `https://testecommerce.vodoglobal.com/api/v2/update_reservation_id/${orderID}`,
        method: "PUT",
        headers: {
          "Accept-Language": "en",
          "secure-business-key": secureBusinessKey,
        },
        data: {
          reservation_id: reservationIds,
          service_id: serviceIds,
          parent_reservation_id: parentReservationIds,
        },
      };

      const reservationResponse = await axios.request(handleReservationOptions);

      // Return only serializable data
      return {
        reservationData: data,
        handleReservationResponse: reservationResponse.data, // Extract only the serializable `data` property
      };
    } catch (error) {
      console.error(error);
      return Promise.reject(error.message); // Use a string or object for better error handling
    }
  }
);

// Initial state for the order slice
// const initialValues = {
//   orderDone: null,
//   reservationResponse: null, // Store the second reservation response here
//   isLoading: false,
//   isError: null,
// };
const orderSlice = createSlice({
  name: "order",
  initialState: {
    orderDone: null,
    reservationResponse: null,
    isLoading: false,
    isError: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderDoneData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orderDone = action.payload.reservationData;
        state.reservationResponse = action.payload.handleReservationResponse; // Store only serializable `data`
      })
      .addCase(getOrderDoneData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrderDoneData.rejected, (state, action) => {
        state.isError = action.payload || action.error.message;
        state.isLoading = false;
      });
  },
});

export const orderReducer = orderSlice.reducer;
//  url: "https://testecommerce.vodoglobal.com/api/v2/update_reservation_id/"
