import * as yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getLoginData } from "../../services/Authentication/authSlice";
import { useNavigate } from "react-router-dom";
import { toast, Bounce } from "react-toastify";
import { FaRegKeyboard } from "react-icons/fa";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

export default function LogIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputName, setInputName] = useState("");
  const [layout, setLayout] = useState("default");
  const [isCapsLock, setIsCapsLock] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isShowKeyboard, setIsShowKeyboard] = useState(false);
  const [input, setInput] = useState({ user_name: "", password: "" });

  const togglePasswordVisibility = (type) => {
    if (type === "show") setShowPassword(!showPassword);
  };

  const logInData = useSelector((state) => state?.auth?.user);
  const logInStatus = logInData?.status;

  useEffect(() => {
    dispatch(getLoginData());
  }, [dispatch]);

  useEffect(() => {
    if (logInStatus === true) {
      notify();
      setTimeout(() => navigate("/"), 2000);
    } else if (logInStatus === false) {
      navigate("/login");
    }
  }, [logInStatus]);

  const validationForm = yup.object({
    user_name: yup.string().required("User Name is required"),
    password: yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: { user_name: "", password: "" },
    onSubmit: (values) => dispatch(getLoginData(values)),
    validationSchema: validationForm,
  });

  const notify = () =>
    toast("Logged in successfully", {
      style: {
        background: "#A4907C",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1.2rem",
        padding: "1rem",
      },
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
      transition: Bounce,
    });

  const handleKeyboardInput = (inputValue) => {
    const updatedInput = { ...input, [inputName]: inputValue };
    setInput(updatedInput);
    formik.setFieldValue(inputName, inputValue);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
    formik.handleChange(e);
  };

  const handleShift = () => {
    setLayout((prevLayout) => (prevLayout === "default" ? "shift" : "default"));
  };

  const handleCapsLock = () => {
    setIsCapsLock(!isCapsLock);
    setLayout(isCapsLock ? "default" : "shift");
  };

  return (
    <>
      <section className="container-box h-[100vh] flex flex-col justify-center items-center gap-[1dvh]">
        <div className="p-[1dvh] rounded-xl border w-[70dvw]  mx-auto">
          <h2 className="text-[1.4dvh] mb-[2dvh] flex items-center gap-2 text-main">
            <FaUserCircle />
            <span className="capitalize">sign in</span>
          </h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-[0.4dvh] border border-[#E5E5E5] gap-[1dvh]rounded-[1dvh]">
              <input
                type="text"
                placeholder="User Name"
                className="w-full text-[1.3dvh] text-gray-500 border-none focus:outline-none rounded-md py-[.4dvh] px-[1dvh] bg-transparent"
                name="user_name"
                onFocus={() => setInputName("user_name")}
                onChange={handleInputChange}
                onBlur={formik.handleBlur}
                value={input.user_name}
              />
            </div>
            {formik.errors.user_name && formik.touched.user_name && (
              <span className="text-red-500">* {formik.errors.user_name}</span>
            )}
            <div className="mb-[0.4dvh] mt-[1dvh] relative border border-[#E5E5E5] gap-[1dvh] rounded-[1dvh] ">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                className="w-full text-[1.3dvh]  text-gray-500 border-none focus:outline-none rounded-md py-[.4dvh] px-[1dvh] bg-transparent"
                name="password"
                onFocus={() => setInputName("password")}
                onChange={handleInputChange}
                onBlur={formik.handleBlur}
                value={input.password}
              />
              <button
                type="button"
                onClick={() => togglePasswordVisibility("show")}
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
              >
                {showPassword ? (
                  <FaEyeSlash className="h-[2.3rem] w-[2.3rem]  text-gray-600" />
                ) : (
                  <FaEye className="h-[2.3rem] w-[2.3rem]  text-gray-600" />
                )}
              </button>
            </div>
            {formik.errors.password && formik.touched.password && (
              <span className="text-red-500">* {formik.errors.password}</span>
            )}
            {logInData?.message === "incorrect user name or password" && (
              <span className="text-red-500">* {logInData?.message}</span>
            )}

            <button
              type="submit"
              className="mainButton w-full py-[0.4dvh] mt-[2dvh] mb-4 font-semibold text-white rounded-[1.2dvh] text-[1.4dvh]"
            >
              Sign In
            </button>
          </form>
          <div
            onClick={() => setIsShowKeyboard(!isShowKeyboard)}
            className="cursor-pointer mx-auto w-fit"
          >
            <FaRegKeyboard className="w-[2.5dvh] h-[2.5dvh]" />
          </div>
        </div>
        <div
          className={`${
            isShowKeyboard ? "" : "hidden"
          } w-full text-main !text-[1.4dvh]`}
        >
          <Keyboard
            layoutName={layout}
            onChange={handleKeyboardInput}
            onKeyPress={(button) => {
              if (button === "{shift}" || button === "{lock}") handleShift();
              if (button === "{lock}") handleCapsLock();
            }}
            className="text-base !text-[4dvh]"
            style={{ fontSize: "40dvh" }}
          />
        </div>
      </section>
    </>
  );
}
