import React from "react";

const Pagination = ({ handlePrevPage, handleNextPage, meta, currentPage }) => {
  return (
    <div className="pagination py-[0.4dvh] z-10">
      <div className="text-[1.2dvh] text-center flex justify-center ">
        <button
          onClick={handlePrevPage}
          disabled={currentPage !== 1}
          className={`bg-main text-white p-[.4dvh] rounded-[.9dvh] cursor-pointer ${
            currentPage !== 1 ? "" : "opacity-40 "
          } `}
        >
          <img
            src="./images/fe--arrow-left.svg"
            alt="back"
            className=""
            loading="lazy"
          />
        </button>
        <p className="px-[.6dvh] text-main my-auto">
          <span>{currentPage}</span> of <span>{meta?.last_page || 1}</span>
        </p>
        <button
          onClick={handleNextPage}
          disabled={currentPage !== meta?.last_page}
          className={`bg-main text-white p-[.4dvh] rounded-[.9dvh] cursor-pointer ${
            currentPage !== meta?.last_page ? "" : "opacity-40 "
          } `}
        >
          <img
            src="./images/fe--arrow-right.svg"
            alt="next"
            className=""
            loading="lazy"
          />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
