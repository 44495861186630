import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { login } from "../api/authentication";

export const getLoginData = createAsyncThunk(
  "auth/loginData",
  async (values) => {
    try {
      const { data } = await login(values);
      console.log(data); // Log the user_id for debugging
      return data;
    } catch (error) {
      // Log error details for debugging if needed
      return error;
    }
  }
);

const initialValues = {
  user: null,
  isLoading: false,
  isError: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialValues,
  extraReducers: (builder) => {
    builder
      .addCase(getLoginData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;

        // Store user details in sessionStorage
        sessionStorage.setItem("token", action.payload.data?.access_token);
        sessionStorage.setItem("brunch_id", action.payload.data?.user_branch);
        sessionStorage.setItem("user_id", action.payload.data?.user_id); // Add user_id
      })
      .addCase(getLoginData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLoginData.rejected, (state, action) => {
        state.isError = action.error;
        state.isLoading = false;
      });
  },
  reducers: {},
});

export const authReducer = authSlice.reducer;
