import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sendCode } from "../api/code";

export const getCodeData = createAsyncThunk("code/getCodeData", async () => {
  const phone = sessionStorage.getItem("phone");

  try {
    const { data } = await sendCode({ phone });
    // console.log(data?.data);
    return data;
  } catch (error) {
    // console.log(error);
    return error;
  }
});

const initialValues = {
  code: null,
  isLoading: false,
  isError: null,
};

const sendCodeSlice = createSlice({
  name: "code",
  initialState: initialValues,
  extraReducers: (builder) => {
    builder
      .addCase(getCodeData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.code = action.payload;
        // sessionStorage.setItem("code", action.payload.data.code);
      })
      .addCase(getCodeData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCodeData.rejected, (state, action) => {
        state.isError = action.error;
        state.isLoading = false;
        // console.log(action.error);
      });
  },
  reducers: {},
});

export const sendCodeReducer = sendCodeSlice.reducer;
