// import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { secureBusinessKey, apiEndpoints } from "../.info";
import { fetchEmployees, fetchSpecialists } from "../api/employees";
export const getEmployeesAndSpecialists = createAsyncThunk(
  "emploees/getEmployeesAndSpecialists",
  async ({ serviceId }, { rejectWithValue }) => {
    try {
      // Fetch all employees
      const employeeResponse = await fetchEmployees({ serviceId });

      // Extract employee IDs
      const employees = employeeResponse.data?.response || [];
      const employeeIds = employees.map((employee) => employee.user_id);
      sessionStorage.setItem("employee_user_ids", JSON.stringify(employeeIds));

      // Fetch available specialists
      const specialistResponse = await fetchSpecialists(employeeIds);

      const availableIds = new Set(
        specialistResponse?.data?.data.map((specialist) => specialist)
      );

      // Filter the employees to include only those who are available
      const availableEmployees = employees.filter((employee) =>
        availableIds.has(employee.user_id)
      );

      console.log("Available specialists data:", specialistResponse.data);
      console.log("Filtered available employees:", availableEmployees);

      return {
        availableEmployees: availableEmployees,
        allSpecialistsData: specialistResponse.data,
      };
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);

const getEmploeesSlice = createSlice({
  name: "emploees",
  initialState: {
    availableEmployees: [],
    allSpecialistsData: null,
    status: "idle", // idle | loading | succeeded | failed
    isError: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeesAndSpecialists.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getEmployeesAndSpecialists.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.availableEmployees = action.payload.availableEmployees;
        state.allSpecialistsData = action.payload.allSpecialistsData;
      })
      .addCase(getEmployeesAndSpecialists.rejected, (state, action) => {
        state.status = "failed";
        state.isError = action.payload;
      });
  },
});

export const getEmploeesReducer = getEmploeesSlice.reducer;
