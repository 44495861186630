import { authReducer } from "../Authentication/authSlice";
import { sendCodeReducer } from "../Code/sendCodeSlice";
import { codeVerifyReducer } from "../Code/codeVerifySlice";
import { servicesReducer } from "../Services/servicesSlice";
import { productsReducer } from "../Products/productsSlice";
import { addToCartReducer } from "../Cart/addToCartSlice";
import { getFromCartReducer } from "../Cart/getFromCartSlice";
import { getEmploeesReducer } from "../Employees/emploeesSlice";
import { orderReducer } from "../Order/orderSlice";
import { orderIdReducer } from "../Order/orderIdSlice";
import { categoriesReducer } from "../Categories/categoriesSlice";

const reducers = {
  auth: authReducer,
  sendCode: sendCodeReducer,
  codeVerify: codeVerifyReducer,
  services: servicesReducer,
  products: productsReducer,
  addToCart: addToCartReducer,
  getFromCart: getFromCartReducer,
  getEmploees: getEmploeesReducer,
  getOrder: orderReducer,
  getOrderId: orderIdReducer,
  getCategories: categoriesReducer,
};

export default reducers;
