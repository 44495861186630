//
//
//
import "./index.css";
import App from "./App";
import React from "react";
import { Store } from "./services/stores/store";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { register } from "swiper/element/bundle";
import { EmployeesProvider } from "./contexts/EmployeesContext";

register();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
    <EmployeesProvider>
      <App />
    </EmployeesProvider>
  </Provider>
);
