import { Suspense } from "react";
import AppLayout from "../src/layout";
import Camera from "../src/pages/camera/camera";
import Welcome from "../src/pages/home/welcome";
import CodeVerification from "../src/pages/codeVerification/codeVerification";
import PhoneVerification from "../src/pages/phoneVerification/phoneVerification";
import DoneOrder from "./pages/orderDone/doneOrder";
import LogIn from "./pages/login/logIn";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";
import DetailsOrder from "./pages/orderDetails/detailsOrder";
import GetStart from "./pages/getStarted/getStart";
import Services from "./pages/services/Services";
import Shop from "./pages/shop/Shop";
import Payment from "./pages/payment/payment";
import Logout from "./layout/logout";
import SessionTimeout from "./components/SessionTimeout";
function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Toaster />
      <SessionTimeout />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route element={<AppLayout />}>
            <Route path="/" element={<Welcome />} />
            <Route path="home" element={<GetStart />} />
            <Route path="services" element={<Services />} />
            <Route path="shop" element={<Shop />} />
            <Route path="camera" element={<Camera />} />
            <Route path="phoneVerification" element={<PhoneVerification />} />
            <Route path="codeVerification" element={<CodeVerification />} />
            <Route path="payment" element={<Payment />} />
            <Route path="doneOrder" element={<DoneOrder />} />
            <Route path="detailsOrder" element={<DetailsOrder />} />
            <Route path="login" element={<LogIn />} />
            <Route path="logout" element={<Logout />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
