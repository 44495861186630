import * as Yup from "yup";
import BackIcon from "../../icons/backIcon";
import { getCodeData } from "../../services/Code/sendCodeSlice";
import { varifyCodeData } from "../../services/Code/codeVerifySlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FaRegKeyboard } from "react-icons/fa";
import { Bounce, toast } from "react-toastify";
// import { getOrderDoneData } from "../services/orderSlice";
import VerificationHeader from "../../components/verificationHeader";
import { getOrderIdData } from "../../services/Order/orderIdSlice";
import { getOrderDoneData } from "../../services/Order/orderSlice";
import Spinner from "../../components/spinner";
import { IoMdClose } from "react-icons/io";

const OTBSchema = Yup.object().shape({
  otp1: Yup.string()
    .required("Required")
    .matches(/^[0-9]$/, "Must be a number"),
  otp2: Yup.string()
    .required("Required")
    .matches(/^[0-9]$/, "Must be a number"),
  otp3: Yup.string()
    .required("Required")
    .matches(/^[0-9]$/, "Must be a number"),
  otp4: Yup.string()
    .required("Required")
    .matches(/^[0-9]$/, "Must be a number"),
});

export default function CodeVerification() {
  const dispatch = useDispatch();
  // const { t } = useTranslation();
  const navigate = useNavigate();
  // const [isShow, setIsShow] = useState(false);
  //
  const [otp, setOtp] = useState({ otp1: "", otp2: "", otp3: "", otp4: "" });
  const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [isShowKeyboard, setIsShowKeyboard] = useState(true);
  const [timeLeft, setTimeLeft] = useState(60);
  const [isLoading, setIsLoading] = useState(false);
  const otp1Ref = useRef(null);
  //
  const codeData = useSelector((state) => state?.sendCode?.code);
  // const codeMessage = codeData?.message;
  const verificationCode = codeData?.data?.code;
  sessionStorage.setItem("code", verificationCode);

  const varifyData = useSelector((state) => state?.codeVerify?.customerId);
  const verifyStatus = varifyData?.status;
  const verifyMessage = varifyData?.message;
  // console.log("varifyData", varifyData);
  console.log("verifyStatus", verifyStatus);
  // console.log("verifyStatus", verifyStatus);
  useEffect(() => {
    setTimeout(() => {
      dispatch(varifyCodeData());
    }, 2000);
  }, []);
  //
  // const [showCode, setShowCode] = useState(true);
  // useEffect(() => {
  //   if (verifyMessage == "code expired") {
  //     setShowCode(false);
  //   } else {
  //     setShowCode(true);
  //   }
  // }, [verifyMessage]);
  //
  // const code = sessionStorage.getItem("code");
  //   // ===== Mask phone number with stars =====
  const phone = sessionStorage.getItem("phone");
  const maskPhone = (phone) => {
    if (phone) {
      const firstPart = phone.slice(0, 5);
      const lastPart = phone.slice(-2);
      return ` ${firstPart}*****${lastPart}`;
    }
    return phone;
  };
  //
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };
  //
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    if (timeLeft == 0) {
      dispatch(varifyCodeData());
    }
  }, [timeLeft]);
  //
  const handleKeyboardClick = (number) => {
    const emptyKey = Object.keys(otp).find((key) => otp[key] === "");
    if (emptyKey) {
      setOtp({ ...otp, [emptyKey]: number });
    }
  };
  //
  const handleDelete = () => {
    const filledKeys = Object.keys(otp).filter((key) => otp[key] !== "");
    const lastFilledKey = filledKeys[filledKeys.length - 1];
    if (lastFilledKey) {
      setOtp({ ...otp, [lastFilledKey]: "" });
    }
  };
  //
  useEffect(() => {
    setTimeout(() => {
      if (otp1Ref.current) {
        otp1Ref.current.focus();
      }
    }, 0);
  }, [dispatch]);
  //
  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (value.match(/^[0-9]$/)) {
      const newOtp = { ...otp, [`otp${index + 1}`]: value };
      setOtp(newOtp);
      if (index < 3 && otpRefs[index + 1].current) {
        otpRefs[index + 1].current.focus(); // Move focus to next input if exists
      }
    } else if (value === "") {
      setOtp({ ...otp, [`otp${index + 1}`]: "" });
    }
  };
  //
  const handleKeyDown = (e, index) => {
    if (
      e.key === "Backspace" &&
      otp[`otp${index + 1}`] === "" &&
      index > 0 &&
      otpRefs[index - 1].current
    ) {
      otpRefs[index - 1].current.focus(); // Move focus to previous input if exists
    }
  };
  //
  // const success = () =>
  //   toast("Order created successfully", {
  //     style: {
  //       background: " #84cc16",
  //       color: "#fff",
  //       fontWeight: "bold",
  //       fontSize: "1.2rem",
  //       padding: "1rem",
  //     },
  //     position: "top-center",
  //     autoClose: 1750,
  //     hideProgressBar: false,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: 0,
  //     theme: "colored",
  //     transition: Bounce,
  //   });
  //
  const fails = () =>
    toast("Code is wrong or expired", {
      style: {
        background: "#f87171",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1.2rem",
        padding: "1rem",
      },
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
      transition: Bounce,
    });
  //
  // const bookAppointmentData = useSelector(
  //   (state) => state?.getOrder?.orderDone
  // );
  // const orderID = useSelector((state) => state?.getOrderId?.orderDone?.data);
  // const orderIDStatus = useSelector(
  //   (state) => state?.getOrderId?.orderDone?.status
  // );
  // const orderHavesService = useSelector(
  //   (state) => state?.getOrderId?.orderDone?.have_service
  // );
  // console.log("orderIDssss", orderID);
  // console.log("orderIDStatus", orderIDStatus);
  // console.log("orderHavesService", orderHavesService);
  // const bookAppointmentStatus = bookAppointmentData?.status;
  // console.log("bookAppointment", bookAppointmentData);

  // useEffect(() => {
  //   dispatch(getOrderIdData());
  // }, []);

  const handleSubmit = async (values) => {
    const code = Object.values(values).join("");
    const sessionCode = sessionStorage.getItem("code");

    if (verifyStatus && code === sessionCode) {
      // setShowCode(false);
      // setIsShow(true);
      setIsLoading(true);

      try {
        await dispatch(getOrderIdData());
        const havesService = sessionStorage.getItem("have_service");

        if (havesService !== "false") {
          await dispatch(getOrderDoneData());
        }
        // else {
        //   throw new Error("Order ID status is not true");
        // }
        setIsLoading(false);
        // After everything is successful, navigate to payment page
        navigate("/payment");
      } catch (error) {
        console.error("Failed to process order data:", error);
        fails(); // Handle errors or failure scenarios
      }
    } else {
      fails(); // OTP verification failed
    }
  };

  return (
    <>
      {isLoading && (
        <div
          className={`
        
         fixed w-full h-full top-0 left-0   z-[1000] flex justify-center items-center`}
        >
          <Spinner />
        </div>
      )}

      <VerificationHeader />
      <article
        className="relative flex flex-col justify-center h-[85vh]"
        id="verification"
      >
        <div
          onClick={() => {
            sessionStorage.removeItem("phone");
            sessionStorage.removeItem("code");
            navigate("/phoneVerification");
          }}
          className="absolute top-[1dvh] p-[.5dvh] rounded-[1.3dvh] left-[0.6dvh] rtl:left-auto rtl:right-[0.6dvh] cursor-pointer bg-[#0000001A] hover:bg-[#00000021]"
        >
          <BackIcon className={"w-[3dvh] h-[3dvh] rtl:rotate-180"} />
        </div>

        <div className="mx-auto w-2/3 border border-white backdrop-blur-md bg-white/30 rounded-2xl overflow-hidden">
          <h1
            className="text-center text-[#66381a] pb-10 pt-4 text-[20px] sm:text-[44px] md:text-[54px] lg:text-[64px]
           xl:text-[80px] 2xl:text-[94px] 3xl:text-[110px] 4xl:text-[120px] 5xl:text-[130px] 6xl:text-[140px] 7xl:text-[160px] font-semibold"
          >
            Code Verification
          </h1>
          <p
            className="relative text-center text-main font-bold text-[16px] sm:text-[20px] md:text-[26px] lg:text-[36px] 
          xl:text-[44px]  2xl:text-[52px]  3xl:text-[65px] 4xl:text-[75px] 5xl:text-[90px] 6xl:text-[110px] 7xl:text-[120px]"
          >
            <span
              className={` ${
                verifyStatus === true ? "" : "hidden"
              } absolute -top-10 left-1/2 -translate-x-1/2 bg-slate-50 px-20 pt-10 pb-6 text-4xl font-semibold text-main rounded-2xl`}
            >
              <span
                // onClick={() => setShowCode(false)}
                className="absolute top-0 left-0 text-start font-bold text-black cursor-pointer"
              >
                <span className="w-[4dvh] h-[4dvh] " loading="lazy">
                  <IoMdClose />
                </span>
              </span>
              <span
                className="text-[20px] sm:text-[26px] md:text-[36px] lg:text-[45px] 
                 xl:text-[57px]  2xl:text-[68px] my-4"
              >
                {verificationCode}
              </span>

              <span className="flex flex-col pt-5">
                <span
                  className="text-[16px] sm:text-[20px] md:text-[26px] lg:text-[36px] 
                 xl:text-[44px]  2xl:text-[52px] "
                >
                  Verify Code
                </span>
                {/* <span className="text-red-500">{code}</span> */}
              </span>
            </span>
            verification code has sent to
          </p>
          <span
            className="mb-8 block w-full text-center text-main font-bold text-[16px] sm:text-[20px] md:text-[26px] lg:text-[36px] 
          xl:text-[44px]  2xl:text-[52px]  3xl:text-[65px] 4xl:text-[75px] 5xl:text-[90px] 6xl:text-[110px] 7xl:text-[120px]"
          >
            {maskPhone(phone)}
          </span>
          <Formik
            initialValues={otp}
            validationSchema={OTBSchema}
            onSubmit={handleSubmit} // Use the handleSubmit function here
            enableReinitialize
          >
            <Form className="flex flex-col gap-[20px] mx-auto w-4/5">
              <div className="flex gap-[14px] md:gap-[24px] lg:gap-[34px] justify-center">
                {["otp1", "otp2", "otp3", "otp4"].map((field, index) => (
                  <div key={index}>
                    <Field
                      name={field}
                      type="text"
                      maxLength="1"
                      className=" w-[90px] h-[70px] md:w-[100px] md:h-[90px]  lg:w-[124px] lg:h-[107px]  2xl:w-[140px] 2xl:h-[120px] 3xl:w-[160px] 3xl:h-[140px] 4xl:w-[180px] 4xl:h-[160px]
                       text-[20px] md:text-[30px] lg:text-[40px] xl:text-[50px]  2xl:text-[58px]  3xl:text-[68px] 4xl:text-[80px] 5xl:text-[90px] 6xl:text-[100px] 7xl:text-[120px] text-center border-2 border-gray-300 rounded-[12px] md:rounded-[16px] lg:rounded-[20px] focus:border-main focus:outline-none"
                      value={otp[field]}
                      innerRef={otpRefs[index]}
                      onChange={(e) => handleOtpChange(e, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                    />
                    <ErrorMessage
                      name={field}
                      component="div"
                      className="text-red-500 text-[16.32px] md:text-[22.32px] lg:text-[30.32px] xl:text-[40px]  2xl:text-[52px]  "
                    />
                  </div>
                ))}
              </div>
              {verifyStatus === false && (
                <span className="text-center text-red-700 text-[16px] md:text-[20px] lg:text-[24px] xl:text-[27px]  2xl:text-[32px]  3xl:text-[37px] 4xl:text-[45px] 5xl:text-[50px] 6xl:text-[60px] 7xl:text-[75px]">
                  ( {verifyMessage} )
                </span>
              )}
              <button
                type="submit"
                className="w-full bg-[#e7ded5] text-main border border-main py-4 my-10 font-bold rounded-[10px] md:rounded-[14px] lg:rounded-[20px] text-[20px] md:text-[24px] lg:text-[30px] xl:text-[40px]  2xl:text-[52px]  3xl:text-[65px] 4xl:text-[75px] 5xl:text-[90px] 6xl:text-[110px] 7xl:text-[120px]"
              >
                Confirm
              </button>
            </Form>
          </Formik>
          <div className="flex flex-col items-center justify-center gap-1 md:text-[25px] lg:text-[40.32px]">
            <p className="text-white font-bold text-[16px] sm:text-[20px] md:text-[26px] lg:text-[36px]  xl:text-[44px]  2xl:text-[52px]  3xl:text-[65px] 4xl:text-[75px] 5xl:text-[90px] 6xl:text-[110px] 7xl:text-[120px]">
              {/* {t("noCode")} */}
              Didn’t receive code?
            </p>
            <button
              onClick={() => {
                if (timeLeft == 0) {
                  dispatch(getCodeData());
                  setTimeout(() => {
                    dispatch(varifyCodeData());
                  }, 1000);
                  setTimeLeft(60);
                }
              }}
              disabled={timeLeft > 0}
              className="text-black font-semibold text-[14px] sm:text-[16px] md:text-[20px]
               lg:text-[24px] xl:text-[27px]  2xl:text-[32px]  3xl:text-[37px] 4xl:text-[45px]
                5xl:text-[50px] 6xl:text-[60px] 7xl:text-[75px]"
            >
              {/* {t("resendCode")} */}
              Resend Code ({formatTime(timeLeft)})
            </button>
          </div>
          <div
            onClick={() => setIsShowKeyboard(true)}
            className={`${
              isShowKeyboard ? "hidden" : ""
            } cursor-pointer mx-auto w-fit text-black`}
          >
            <FaRegKeyboard
              className="w-[30px] h-[30px] md:w-[40px] md:h-[40px] lg:w-[50px] lg:h-[50px] xl:w-[60px] 
            xl:h-[60px] 2xl:w-[70px] 2xl:h-[70px] 3xl:w-[80px] 3xl:h-[80px] 4xl:w-[90px] 4xl:h-[90px] 5xl:w-[100px] 
            5xl:h-[100px] 6xl:w-[110px] 6xl:h-[110px] 7xl:w-[120px] 7xl:h-[120px]"
            />
          </div>
          {/* keyboard */}
          <div
            className={`${
              isShowKeyboard ? "" : "hidden"
            } bg-white w-full pb-[1dvh]`}
          >
            <div
              onClick={() => setIsShowKeyboard(false)}
              className="
            w-[8dvh] cursor-pointer ms-[1dvh]  text-[2.2dvh]
            "
              loading="lazy"
            >
              <IoMdClose />
            </div>
            <div
              className={`${
                isShowKeyboard ? "block" : "hidden"
              } grid grid-cols-3 gap-[0.5dvh] my-[0.3dvh] w-full mx-auto`}
            >
              {["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].map((num) => (
                <button
                  key={num}
                  onClick={() => handleKeyboardClick(num)}
                  className="p-[0.5dvh] bg-gray-300 text-[2dvh] rounded-lg"
                >
                  {num}
                </button>
              ))}
              <button
                onClick={handleDelete}
                className="col-span-2 p-[0.5dvh] bg-main text-white text-[2dvh] rounded-lg"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
