import { useNavigate } from "react-router-dom";

function BackButton() {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(-1);
      }}
      className="absolute top-[1dvh] left-[1dvh] z-50 py-[.6dvh] px-[0.8dvh] border-[0.2dvh] border-black/40 rounded-[.8dvh]"
    >
      <img
        src="./images/ArrowBendUpLeft.svg"
        alt="SearchIcon"
        className="w-[2dvh] h-[2dvh]   cursor-pointer"
        loading="lazy"
      />
    </div>
  );
}

export default BackButton;
