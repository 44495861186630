import { useState, useEffect } from "react";

export default function ServicesCard({
  item,
  handleGetEmployees,
  serviceSelectedId,
}) {
  const [isActive, setIsActive] = useState(false);

  // Use useEffect to toggle `isActive` based on `serviceSelectedId`
  useEffect(() => {
    if (serviceSelectedId === item?.id) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [serviceSelectedId, item?.id]);

  return (
    <div
      // Add an ID to this div for positioning the tooltip
      className="relative cursor-pointer"
      id={`service-${item?.id}`}
      onClick={() => handleGetEmployees(item?.id)}
    >
      <div className="bg-slate-200 w-[12dvh] h-[12dvh] rounded-full mx-auto">
        <img
          className={`object-cover w-full h-full object-center rounded-full duration-500 ${
            isActive
              ? "border-[.5dvh] border-[#A4907C]  fill-[#EFE8E2] opacity-70"
              : ""
          } `}
          src={item?.image}
          alt={item?.name}
          loading="lazy"
        />
      </div>
      <div className="my-4 mx-auto w-fit">
        <h3 className="text-[1.4dvh] font-bold text-center ">
          {item?.descriptions?.[0]?.name || item?.keyword || "No Name"}
        </h3>
        <p className="text-[1.1dvh]  text-[#A4907C] font-bold text-center">
          {item?.customer_price || "00.00"} SAR
        </p>
      </div>
    </div>
  );
}
