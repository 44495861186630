import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { secureBusinessKey, apiEndpoints } from "../.info";

export const getDataCart = createAsyncThunk(
  "getFromCart/getDataCart",
  async () => {
    const userId = localStorage.getItem("user_id");
    const options = {
      url: apiEndpoints.getFromCart.url,
      method: "GET",
      headers: {
        "secure-business-key": secureBusinessKey,
        "user-id": userId,
        lang: "en",
      },
    };

    try {
      const { data } = await axios.request(options);
      // console.log(data);
      return data;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
);

const initialValues = {
  getCartItems: null,
  isLoading: false,
  isError: null,
};

const getFromCartSlice = createSlice({
  name: "getFromCart",
  initialState: initialValues,
  extraReducers: (builder) => {
    builder
      .addCase(getDataCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getCartItems = action.payload;
        // console.log(action.payload);
      })
      .addCase(getDataCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDataCart.rejected, (state, action) => {
        state.isError = action.error;
        state.isLoading = false;
        // console.log(action.error);
      });
  },
  reducers: {},
});

export const getFromCartReducer = getFromCartSlice.reducer;
