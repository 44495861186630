import axiosClient from "./axiosClient";
import { apiEndpoints, secureBusinessKey } from "./apiEndpoints";

// API call to verify a code
export const verifyCode = ({ phone, code }) =>
  axiosClient.post(
    apiEndpoints.codeVerification.url,
    {
      customer_phone: phone,
      code: code,
    },
    {
      headers: {
        apikey: apiEndpoints.codeVerification.apikey,
        "secure-business-key": secureBusinessKey,
      },
    }
  );

// API call to send a verification code
export const sendCode = ({ phone }) =>
  axiosClient.post(
    apiEndpoints.sendCode.url,
    {
      customer_phone: phone,
    },
    {
      headers: {
        apikey: apiEndpoints.sendCode.apikey,
        "secure-business-key": secureBusinessKey,
      },
    }
  );
