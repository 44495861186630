import axiosClient from "./axiosClient";
import { apiEndpoints, secureBusinessKey } from "./apiEndpoints";

export const fetchCategories = () =>
  axiosClient.get(apiEndpoints.categories.url, {
    headers: {
      apikey: apiEndpoints.categories.apikey,
      "secure-business-key": secureBusinessKey,
      apiconnection: "appmobile",
      lang: "en",
    },
  });
