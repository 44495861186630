import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function DoneOrder() {
  const navigate = useNavigate();

  return (
    <article className="flex flex-col justify-center h-[100vh]">
      <div className="md:px-[37px] lg:px-[47px]">
        <div className="w-full flex flex-col justify-center items-center py-4">
          <img
            src="./images/doneOrder.png"
            alt="doneOrder"
            className="w-[26vw]"
            loading="lazy"
          />

          <p className="text-[16px] sm:text-[22px] md:text-[30px] lg:text-[38px] xl:text-[45px]  2xl:text-[56px]  3xl:text-[66px] 4xl:text-[75px] 5xl:text-[80px] 6xl:text-[87px] 7xl:text-[100px] text-center text-gray-600">
            Order Done Successfully
          </p>
        </div>
        <div className="w-full flex justify-center items-center gap-[1dvh]">
          <div
            onClick={() => {
              sessionStorage.removeItem("phone");
              sessionStorage.removeItem("cartItems");
              sessionStorage.removeItem("code");
              sessionStorage.removeItem("customer_id");
              navigate("/");
            }}
            className="cursor-pointer"
          >
            <p className="p-4 bg-main text-white text-[16px] sm:text-[22px] md:text-[30px] lg:text-[38px] xl:text-[45px]  2xl:text-[56px]  3xl:text-[66px] 4xl:text-[75px] 5xl:text-[80px] 6xl:text-[87px] 7xl:text-[100px] rounded-[0.5dvh] text-center ">
              New Operation
            </p>
          </div>
          <Link to="/detailsOrder">
            <p className="p-4 bg-main text-white text-[16px] sm:text-[22px] md:text-[30px] lg:text-[38px] xl:text-[45px]  2xl:text-[56px]  3xl:text-[66px] 4xl:text-[75px] 5xl:text-[80px] 6xl:text-[87px] 7xl:text-[100px] rounded-[0.5dvh] text-center ">
              Order Details
            </p>
          </Link>
        </div>
      </div>
    </article>
  );
}
