// import { useEffect } from "react";
import ProductsAddRemove from "../../../components/productAddRemove";

export default function ProductsCard({
  item,
  handleAddToCart,
  cartItems,
  handleDecreaseQty,
  handleIncreaseQty,
}) {
  const matchingItems = cartItems?.product?.filter(
    (cartItem) => cartItem.id === item?.id
  );

  // useEffect(() => {
  //   console.log("Matching Items:", matchingItems);
  // }, [matchingItems]);

  return (
    <>
      <div
        className={` h-[29.2dvw] border-[0.14dvh] rounded-[1.2dvh] border-[#D9D9D9] ${
          matchingItems?.length > 0 ? "bg-[#FAF9F5]" : "bg-[#ffffff]"
        }`}
      >
        <div
          className="w-[12dvw] h-[12dvw]   mx-auto cursor-pointer"
          onClick={() => {
            handleAddToCart(item);
          }}
        >
          <img
            className="w-full h-[7dvh]   object-cover mt-[.5dvh]  border-none mx-auto"
            src={item?.image}
            alt={item?.name}
            loading="lazy"
          />
        </div>
        <div className="my-4 mr-auto w-fit mx-auto px-[1dvw] text-center flex flex-col justify-between">
          <div className="relative group">
            <h3 className="text-[1.8dvw] font-bold duration-700 min-h-[3.5dvh] ">
              {item?.descriptions[0]?.name?.length > 23
                ? `${item.descriptions[0].name.substring(0, 23)}...`
                : item?.descriptions[0]?.name ||
                  (item?.keyword?.length > 23
                    ? `${item.keyword.substring(0, 23)}...`
                    : item?.keyword) ||
                  "No Name"}
            </h3>
            {/* Tooltip */}
            {item?.descriptions[0]?.name?.length > 23 && (
              <div className="absolute left-0 font-bold  rounded-full top-0 text-[1.2dvw] z-10 hidden w-max max-w-[30dvw] p-[.8dvw] bg-black text-white duration-700 transition ease-in-out delay-150    group-hover:block">
                {item?.descriptions[0]?.name || item?.keyword || "No Name"}
              </div>
            )}
          </div>
          <p className="text-[1.5dvw] pb-[0.5dvh] text-gray-700 font-bold">
            SAR {item?.customer_price}{" "}
            <span className="text-discountText line-through">
              {item?.discount || "SAR 50"}
            </span>
          </p>
          {
            <ProductsAddRemove
              item={item}
              handleIncreaseQty={handleIncreaseQty}
              handleDecreaseQty={handleDecreaseQty}
              cartItems={cartItems}
            />
          }
        </div>
      </div>
    </>
  );
}
