import axiosClient from "./axiosClient";
import { apiEndpoints, secureBusinessKey } from "./apiEndpoints";

export const fetchServices = ({ pageNumber, keyWord, currentPage }) =>
  axiosClient.get(
    apiEndpoints.getServices.url({ pageNumber, keyWord, currentPage }),
    {
      method: "GET",
      headers: {
        apikey: apiEndpoints.getServices.apikey,
        "secure-business-key": secureBusinessKey,
        apiconnection: "appmobile",
        lang: "en",
      },
    }
  );
